.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list {
  text-align: left;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}


div.preview-images > img {
  width: 30%;
}


.Collapsible__trigger {
cursor: pointer;
border: solid 1px #f2f2f2;
padding: 15px;
background-color: #0089CC;
color: #FFF;
font-family: verdana;
}

.Collapsible__contentInner {
cursor: pointer;
border-left: solid 1px #f2f2f2;
border-right: solid 1px #f2f2f2;
border-bottom: solid 1px #f2f2f2;
border-radius: 0 0 5px 5px;
padding: 15px;
font-family: verdana;
font-size: 14px;
}

.list .action {
  cursor: pointer;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 600px;
  margin: auto;
}

#root {
    width: 100%;
}

.box {
  display: grid;
  width: 80%;
  grid-template-columns: repeat(auto-fit, minmax(50px, 90px));
  align-items: start;
  justify-content: space-between;
}

.side {
   display:inline-block;
   vertical-align:top;
   grid-gap: 5px;
}

.disabled-link {
  pointer-events: none;
}

.responsive {
  max-width: 100%;
  height: auto;
}

.navbar-custom {
    background-color: rgb(0, 0, 180);
}

.navbar-dark .navbar-nav .nav-link {
   color: white;
}

nav a {
  transition: 0.3s ease;
  color: white;
  text-decoration: none;
  padding: 20px 0;
  margin: 0 5px;
  font-size: 17px;
}

nav a:hover {
  //border-bottom: 2px solid white;
  padding: 6px 0;
  color: white;
}

.required {
  color: red;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label,
 .inputfileLabel {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-size: 1em;
    color: white;
    background-color: #337aff;
	cursor: pointer; /* "hand" cursor */
    border-radius: 4px;
}

.inputfileLabel:hover,
.inputfile:focus + label,
.inputfile + label:hover {
    background-color: #4868bc;
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfileLabel *,
.inputfile + label * {
	pointer-events: none;
}